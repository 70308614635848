<template>
  <ion-page>
    <div id="dashboard">
      <base-layout>
        <header-bar></header-bar>
        <ion-content :scroll-x="false" :scroll-y="false">
          <div class="header-text">
          </div>


          <div class="dashboard-content" style="overflow:scroll;padding-bottom:70px">
            <div class="loading-indicator" v-if="menulinks.length == 0">
              <ion-spinner></ion-spinner>
              <div>Daten werden initialisiert</div>
            </div>
            <div class="menu-wrapper">
              <a
                  @click="openLink(link)"
                  class="tile-item"
                  style="position:relative;">
                <i class="dashboard-item-icon ion-log-in" ></i>

                <span class="text"  style="color: #435959">Registrierte Teilnehmer</span>
              </a>
              <a
                  href="#/app/dashboard"
                  class="tile-item"
                  style="position:relative; background: white !important">
                <i class="dashboard-item-icon ion-clipboard" ></i>

                <span class="text"  style="color: #435959">Vor Ort Registrierungen</span>
              </a>
              <a
                  href="#/app/sessiontypes/da7ad0c0-3ed1-4500-1345-0b0000000281"
                  class="tile-item"
                  style="position:relative; background: white !important">
                <i class="dashboard-item-icon ion-ios-list-outline" ></i>

                <span class="text"  style="color: #435959">Zu den Symposien</span>
              </a>



            </div>
          </div>

        </ion-content>
      </base-layout>
    </div>
  </ion-page>
</template>
<script>
import {
  IonPage,
  IonSpinner,
  IonContent,
  toastController
} from "@ionic/vue";
import {mapState, mapActions, mapGetters} from "vuex";
import {isPlatform} from '@ionic/vue';
import router from "@/router";
import {store} from "@/store";

const config = require('@/config')

export default {
  components: {
    IonPage,
    IonSpinner,
    IonContent,
  },
  data() {
    return {
      isDesktop: null,
      menulinks: [],
      title: config.full_name,
      confDate: config.conf_date,
      currentTime: '',
      polls: [],
      questions: [],
    }
  },
  computed: {
    ...mapState('sync', ['updateFinished', 'syncActive', 'downloadSize', 'downloadLabel', 'currentProgress']),
    ...mapState('personalDB', ['unreadNews']),
    ...mapGetters('auth', ['user'])
  },
  methods: {
    ...mapActions('menulinks', ['getAllLinks', 'getParents']),
    ...mapActions('sync', ['start']),
    ...mapActions('auth', ['GET_USERDATA']),


    async showSyncToast() {
      const toast = await toastController
          .create({
            message: 'Application is now up to date',
            duration: 2000,
            position: 'bottom',
            animated: 'true',
            color: 'primary',

          })
      return toast.present();
    },


    checkRedirect(link) {
      if (!link.target || link.target.indexOf('undefined') != -1 || link.target == 'null') {
        link.redirect = '/app/dashboard/' + link.id;
      } else {
        if (new RegExp("^(http|https)://").test(link.target)) {
          link.redirect = link.target
        } else {
          link.redirect = link.target.split('#')[1]
        }

      }
    },

    isExternalLink(link) {
      return new RegExp("^(http|https)://").test(link.target)
    },

    openLink() {

      if (!this.user) {
        this.login();
      } else {
        router.push('/app/dashboard')
      }
    },

    async update() {
      let res = await this.start();
      if (res) {
        this.showSyncToast();
      }
    },
    determineTarget(link) {
      return link.redirect;
    },
    async prepareLinks() {
      this.menulinks = (await this.getParents()).sort((a, b) => a.order - b.order);
      this.menulinks.map(link => {
        try {
          link._title = JSON.parse(link.title);
        } catch (err) {
          link._title = link.title;
        }
        this.checkRedirect(link);
      });
    },
    async loginByToken(tokenSent) {
      //check for token in path
      let token = tokenSent
      if (token) {

        localStorage.setItem("x-api-key", token);

        let userRequest = await store.dispatch("auth/GET_USERDATA");
        //this.user = userRequest.data;

        let tmpUser = userRequest.data;

        if (tmpUser) {

          await store.commit("auth/SET_USER", tmpUser);
          localStorage.setItem('user', JSON.stringify(tmpUser));
        }
        //splice the path
        window.history.pushState(
            {},
            document.title,
            '/'
        );
        router.push({name: 'Dashboard'});

      } else {
        router.next();
      }
      router.next();

    },


    async login() {
      console.log("login")
      let self = this
      localStorage.setItem('lastPageBeforeLogin', location.hash);
      //let frontendKey = config.auth_key;
      if (isPlatform('ios') && typeof cordova != 'undefined') {
        let ref = cordova.InAppBrowser.open("https://auth.documedias.com/application/" + config.auth_dev_key, '_blank', 'location=yes', 'clearcache=no', 'clearsessioncache=no'); // eslint-disable-line

        let redirect = (param) => {
          if (param && param.indexOf('token=') != -1) {
            let token = param.split('token=')[1];
            if (token) {
              self.loginByToken(token);
              this.loginAlertOpen = false;
              ref.close();
            }
          }
        }

        ref.addEventListener('loaderror', function (event) {
          ref.executeScript({code: "document.redirectLink = (document.getElementById('redirect').href);"}, ((link) => {
            redirect(link.toString());
          }));
          if (event.url.indexOf('token=')) {
            let token = event.url.split('token=')[1];
            if (token) {
              self.loginByToken(token);
              this.loginAlertOpen = false;
              ref.close();
            }
          }
        });


      } else if (isPlatform('android') && typeof cordova != 'undefined') {

        let ref = cordova.InAppBrowser.open("https://auth.documedias.com/application/" + config.auth_dev_key, '_blank', 'location=yes', 'clearcache=no', 'clearsessioncache=no'); // eslint-disable-line
        ref.addEventListener('loaderror', function (event) {

          if (event.url.indexOf('token=')) {
            let token = event.url.split('token=')[1];
            if (token) {
              self.loginByToken(token);
              this.loginAlertOpen = false;
              ref.close();
            }
          }
        });
      } else {
        console.log("essta")
        //window.location.href = "https://auth.documedias.com/application/" + config.auth_dev_key
        window.location.href = "https://auth.documedias.com/application/" + (process.env.NODE_ENV == 'production' ? config.auth_key : config.auth_dev_key)

      }

    },
  },
  async created() {
    this.isDesktop = isPlatform('desktop');
    await this.prepareLinks();


  },
  watch: {
    updateFinished: async function (newVal) {
      if (newVal) {
        this.prepareLinks();
      }
    },
  },
};
</script>
<style lang="scss">


</style>